<template>
  <div id="app">
    <nav class="navbar navbar-expand navbar-dark bg-dark">
      <router-link to="/productos" class="navbar-brand" style="font-size: 15px;">Productos LAM Guadalajara</router-link>
      <div class="navbar-nav mr-auto">
        <!-- <li class="nav-item">
          <router-link to="/home" class="nav-link">
            <font-awesome-icon icon="home" /> Home
          </router-link>
        </li> -->
        <li v-if="showAdminBoard" class="nav-item">
          <router-link to="/admin" class="nav-link">Admin Board</router-link>
        </li>
        <li v-if="showModeratorBoard" class="nav-item">
          <router-link to="/mod" class="nav-link">Moderator Board</router-link>
        </li>
        <!-- <li class="nav-item">
          <router-link v-if="currentUser" to="/user" class="nav-link">User</router-link>
        </li> -->
        <li class="nav-item">
          <router-link v-if="currentUser" to="/admventas" class="nav-link">Ventas</router-link>
        </li>
        <li class="nav-item">
          <router-link v-if="currentUser" to="/admproductos" class="nav-link">Productos</router-link>
        </li>
        <li class="nav-item">
          <router-link v-if="!currentUser" to="/productos" class="nav-link">Productos</router-link>
        </li>
        <li class="nav-item">
          <router-link v-if="!currentUser" to="/carrito" class="nav-link">Carrito ({{totalProducts}})</router-link>
        </li>
      </div>

      <div v-if="!currentUser" class="navbar-nav ml-auto">
        <!-- <li class="nav-item">
          <router-link to="/register" class="nav-link">
            <font-awesome-icon icon="user-plus" /> Sign Up
          </router-link>
        </li> -->
        <!-- <li class="nav-item">
          <router-link to="/admlogin" class="nav-link">
            <font-awesome-icon icon="sign-in-alt" /> Login
          </router-link>
        </li> -->
      </div>

      <div v-if="currentUser" class="navbar-nav ml-auto">
        <li class="nav-item">
          <router-link to="/profile" class="nav-link">
            <font-awesome-icon icon="user" />
            {{ currentUser.username }}
          </router-link>
        </li>
        <li class="nav-item">
          <a class="nav-link" @click.prevent="logOut">
            <font-awesome-icon icon="sign-out-alt" /> LogOut
          </a>
        </li>
      </div>
    </nav>

    <div class="container" >
      <router-view />
    </div>
  </div>
</template>

<style>
body {
  background-color: #eaeaea !important
}
</style>

<script>
import { provide, getCurrentInstance  } from 'vue';
export default {
  data() {
    return{
      totalProducts: 0
    }
  },
  mounted() {
    this.updateCart();
    document.title = 'Productos LAM Guadalajara';
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    showAdminBoard() {
      if (this.currentUser && this.currentUser['roles']) {
        return this.currentUser['roles'].includes('ROLE_ADMIN');
      }

      return false;
    },
    showModeratorBoard() {
      if (this.currentUser && this.currentUser['roles']) {
        return this.currentUser['roles'].includes('ROLE_MODERATOR');
      }

      return false;
    }
  },
  setup() {
    const instance = getCurrentInstance();

    const updateProductsCart = () => {
      if (instance && instance.proxy) {
        instance.proxy.updateCart();
      }
    };

    provide('updateProductsCart', updateProductsCart);
  },
  methods: {
    logOut() {
      this.$store.dispatch('auth/logout');
      this.$router.push('/admlogin');
    },
    updateCart(){
      let productsStorege = JSON.parse(localStorage.getItem("products")) || [];
      const totalQuantity = productsStorege.reduce((sum, product) => sum + product.quantity, 0);
      this.totalProducts = totalQuantity;
    }
  }
};
</script>