import { createWebHistory, createRouter } from "vue-router";
import HomeComponent from "./components/HomeComponent.vue";
import Login from "./components/Login.vue";
import Register from "./components/Register.vue";
// lazy-loaded
const Profile = () => import("./components/Profile.vue")
const BoardAdminComponent = () => import("./components/BoardAdminComponent.vue")
const BoardModeratorComponent = () => import("./components/BoardModeratorComponent.vue")
const BoardUserComponent = () => import("./components/BoardUserComponent.vue")
const SalesComponent = () => import("./components/SalesComponent.vue")
const ProductsComponent = () => import("./components/ProductsComponent.vue")
const ProductsCustomerComponent = () => import("./components/ProductsCustomerComponent.vue")
const CartComponent = () => import("./components/CartComponent.vue")

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeComponent,
    redirect: () => {
      const loggedIn = localStorage.getItem('user');
      return loggedIn ? '/admproductos' : '/productos';
    }
  },
  {
    path: "/home",
    component: HomeComponent,
  },
  {
    path: "/admlogin",
    name: "Login",
    component: Login,
  },
  {
    path: "/register",
    component: Register,
  },
  {
    path: "/profile",
    name: "profile",
    // lazy-loaded
    component: Profile,
  },
  {
    path: "/admin",
    name: "admin",
    // lazy-loaded
    component: BoardAdminComponent,
  },
  {
    path: "/mod",
    name: "moderator",
    // lazy-loaded
    component: BoardModeratorComponent,
  },
  {
    path: "/user",
    name: "user",
    // lazy-loaded
    component: BoardUserComponent,
  },
  {
    path: "/admventas",
    name: "admventas",
    // lazy-loaded
    component: SalesComponent,
  },
  {
    path: "/admproductos",
    name: "admproductos",
    // lazy-loaded
    component: ProductsComponent,
  },
  {
    path: "/productos",
    name: "productos",
    // lazy-loaded
    component: ProductsCustomerComponent,
  },
  {
    path: "/carrito",
    name: "carrito",
    // lazy-loaded
    component: CartComponent,
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: () => {
      const loggedIn = localStorage.getItem('user');
      return loggedIn ? '/admproductos' : '/productos';
    }
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const publicPages = ['/admlogin', '/register', '/home', '/productos', '/carrito'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');

  // trying to access a restricted page + not logged in
  // redirect to admlogin page
  if (authRequired && !loggedIn) {
    next('/admlogin');
  } else {
    next();
  }
});

export default router;